import React from 'react'

function Footer() {

  return (
    <footer className="footer">
      <div className="footer__content">
        <p>
          <small>
            Created by <a href="https://github.com/mikejoyceio" target="_blank">Mike</a>
          </small>
        </p>
      </div>
    </footer>
  )
}

export default Footer
