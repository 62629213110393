import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

function Header({ siteTitle }) {

  return (
    <header className="header">
      <div className="header__content">
        <h1 className="header__heading">
          {siteTitle}
        </h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
